<template>
  <div id="app">
    <!-- TODO:Add Layout  -->
    <router-view />
  </div>
</template>

<script>

export default {
  name: "App",
};
</script>
<style>
#app {
  padding-bottom: 100px;
  background-color:rgba(255,255,255,0);
}
  p {
    color: #aaa;
  }

</style>